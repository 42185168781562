<template>
    <div class="pross-grid dark-template-bg">
        <div class="container">
            <!-- container-start -->
            <div class="row align-item-center">
                <!-- row start -->
                <div class="col-sm-3">
                    <!-- column start -->
                    <h2>ApeiroX future</h2>
                </div>
                <!-- column end -->
                <div class="col-sm-9 right-bdr">
                    <!-- column start -->
                    <div class="row text-center">
                    <!-- row start -->
                    <div class="pross-item col-sm-3">
                        <!-- <img src="images/accepted-worldwide.svg" alt="logo" width="65" /> -->
                        <h3>Used Worldwide</h3>
                    </div>
                    <div class="pross-item col-sm-3">
                        <!-- <img src="images/decentralised-currency.svg" alt="logo" width="65" /> -->
                        <h3>Countless Jobs Created</h3>
                    </div>
                    <div class="pross-item col-sm-3">
                        <!-- <img src="images/safe-and-secure.svg" alt="logo" width="65" /> -->
                        <h3>Safe And Secure</h3>
                    </div>
                    <div class="pross-item col-sm-3">
                        <!-- <img src="images/low-risk.svg" alt="logo" width="65" /> -->
                        <h3>Community Participation</h3>
                    </div>
                    </div>
                </div>
                <!-- column end -->
            </div>
            <!-- row end -->
        </div>
        <!-- container end -->
    </div>
</template>

<script>
export default {
    name: "WhyApeiroX"
}
</script>