<template>
  <div class="home">
    <SiteLoader />
    <NavBar />
    <Hero />
    <section id="about">
      <AboutApeiroX />
      <WhyApeiroX />
      <AboutSpecial />
    </section>
    <!-- <BuyToken /> -->
    <Service />
    <RoadMap />
    <SaleEnds />
    <!-- <WhitePaper /> -->
    <Faq />
    <Contact />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";
import Contact from "@/components/Contact.vue";
import Faq from "@/components/Faq.vue";
// import WhitePaper from "@/components/WhitePaper.vue";
import SaleEnds from "@/components/SaleEnds.vue";
import RoadMap from "@/components/RoadMap.vue";
import Service from "@/components/Service.vue";
// import BuyToken from "@/components/BuyToken.vue";
import AboutSpecial from "@/components/AboutSpecial.vue";
import WhyApeiroX from "@/components/WhyApeiroX.vue";
import AboutApeiroX from "@/components/AboutApeiroX.vue";
import Hero from "@/components/Hero.vue";
import NavBar from "@/components/NavBar.vue";
import SiteLoader from "@/components/SiteLoader.vue";

export default {
  name: "Home",
  components: {
    Footer,
    Contact,
    Faq,
    // WhitePaper,
    SaleEnds,
    RoadMap,
    Service,
    // BuyToken,
    AboutSpecial,
    WhyApeiroX,
    AboutApeiroX,
    Hero,
    NavBar,
    SiteLoader
  },
};
</script>
