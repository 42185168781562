<template>
    <div class="about-cryptency burger dark-template-light-bg">
        <div class="container">
            <!-- container-start -->
            <div class="row align-item-center">
                <!-- row start -->
                <div class="col-sm-5 col-sm-offset-1 col-sm-push-6">
                    <!-- column start -->
                    <img src="images/ecom3.gif" alt="frame" class="img-responsive cryptency-graph" style="box-shadow: 0px 0px 5px 5px #151b2c">
                </div>
                <!-- column end -->
                <div class="col-sm-6 col-sm-pull-6">
                    <!-- column start -->
                    <h2 >What is ApeiroX?</h2>
                    <p class="big-pera">ApeiroX is a trust-less, flexibly safe e-commerce platform that introduce blockchain benefits to commerce and link local suppliers to both local and foreign markets, with the aim of reducing financial disparities and improving product accessibility. .</p>
                    <!-- <div class="check-list mt2">
                    <div class="media">
                        <img src="images/checked.svg" alt="cehck" width="30" class="pull-left">
                        <div class="media-body">
                            <h3>Bitcoin calculate.</h3>
                        </div>
                    </div>
                    <div class="media">
                        <img src="images/checked.svg" alt="cehck" width="30" class="pull-left">
                        <div class="media-body">
                            <h3>Money Expanding for each trade.</h3>
                        </div>
                    </div>
                    <div class="media">
                        <img src="images/checked.svg" alt="cehck" width="30" class="pull-left">
                        <div class="media-body">
                            <h3>Sell and  buy bitcoin, Entherem, Other crypto.</h3>
                        </div>
                    </div>
                    </div> -->
                    <a href="https://apeirox.com" class="btn mt3 btn-alpha" target="_blank">Know more </a>
                </div>
                <!-- column end -->
            </div>
            <!-- row end -->
        </div>
        <!-- container end -->
    </div>
</template>

<script>
export default {
    name: "AboutApeiroX",
    methods: {
        GoToMainSite() {
            this.$router.push("https://apeirox.com");
        }
    }
}
</script>