<template>
    <section class="burger cta dark-template-light-bg">
        <div class="container">
        <!-- container-start -->
        <div class="row align-item-center">
            <!-- row-start -->
            <div class="col-md-1 col-sm-2">
                <!-- column start -->
                <img src="images/clock.svg" width="70" alt="clock">
            </div>
            <!-- column end -->
            <div class="col-sm-9 col-md-9 ">
                <!-- column start -->
                <h2> 1st Round of Sale Ended on <span class="date">30 June 2021 | 23:59 GMT</span></h2>
            </div>
            <!-- column end -->
            <!-- <div class="col-sm-2 col-md-2"> -->
                <!-- column start -->
                <!-- <a href="/waitlist_form"  class="btn-white"> JOIN WAITLIST</a> -->
            <!-- </div> -->
            <!-- column end -->    
        </div>
        <!-- row end -->
        </div>
        <!-- container end -->
    </section>
</template>

<script>
export default {
    name: "SaleEnds"
}
</script>