import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sent_contact_message: false,
    subscribed_newsletter: false,
    joined_waitlist: false,
    waitlist_check: false
  },
  mutations: {
    setSentContactMessage(state) {
      state.sent_contact_message = true;
    },
    setSubscribeNewsletter(state) {
      state.subscribed_newsletter = true;
    },
    setJoinPrivateSale(state) {
      state.joined_waitlist = true;
    },
    setCheckedWaitlistValidation(state) {
      state.waitlist_check = true;
    },
  },
  actions: {
    SendContactMessage({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("contact_message", {
              email: credentials.email,
              message: credentials.message,
              first_name: credentials.first_name,
              last_name: credentials.last_name
            })
          .then(response => {
            resolve(response);
            commit("setSentContactMessage");
          })
          .catch(error => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    },

    SubscribeNewsletter({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("apeirox_newsletter", {
              email: credentials.email
            })
          .then(response => {
            resolve(response);
            commit("setSubscribeNewsletter");
          })
          .catch(error => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    },

    JoinPrivateSale({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("private_sale_waitlist", credentials)
          .then(response => {
            resolve(response);
            commit("setJoinPrivateSale");
          })
          .catch(error => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    },

    WaitlistCheck({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("waitlist_check", credentials)
          .then(response => {
            resolve(response);
            commit("setCheckedWaitlistValidation");
          })
          .catch(error => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    },
  },
  modules: {},
});
