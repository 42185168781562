<template>
    <footer class="footer burger dark-template-bg">
        <div class="container">
        <!-- container-start -->
        <div class="row">
            <!-- row-start -->
            <div class="subscribe-box col-sm-12 text-center">
                <!-- column start -->
                <span class="circular-at"><img src="images/at.svg" alt="at" ></span>
                <h2 class="mb5 text-capitalize">Subscribe our Newsletter for free Update &amp; News</h2>
                <form action="newsletter.php" id="subscribe_now" class="subscribe-form" method="post">
                    <div id="mail-messages" class="notification subscribe"></div>
                    <i class="fa fa-envelope"></i>
                    <input type="email" name="subscribe_email" id="subscribe_email" class="subscribe-input" placeholder="Ente your email address" v-model="email" required>
                    <button type="button" @click.prevent="subscribeNewsletter" class="btn-alpha" >SUBSCRIBE NOW</button>
                </form>
            </div>
            <!-- column end -->
            <div class="text-center col-sm-12">
                <!-- column start -->
                <ul class="social list-unstyled">
                    <li><a href="https://twitter.com/apeiroxcom" target="_blank"><i class="fa fa-twitter"></i></a></li>
                    <li><a href="https://facebook.com/apeiroxcom" target="_blank"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="https://instagram.com/apeiroxcom" target="_blank"><i class="fa fa-instagram"></i></a></li>
                    <li><a href="https://github.com/ApeiroXContract/ApeiroX" target="_blank"><i class="fa fa-github"></i></a></li>
                    <li><a href="https://t.me/apeiroxcom" target="_blank"><i class="fa fa-telegram"></i></a></li>
                    <li><a href="https://medium.com/@apeirox" target="_blank"><i class="fa fa-medium"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/apeirox" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                </ul>
                <p class="copyright">All rights reserved <a href="https://apeirox.com" target="_blank" class="template-color"><strong>ApeiroX</strong></a> Copyright &copy; 2021</p>
            </div>
            <!-- column end -->
        </div>
        <!-- row-end --> 
        </div>
        <!-- container-end -->
    </footer>
</template>

<script>
export default {
    name: "Footer",
    data: function() {
        return {
            email: "",
            spin: false,
            notSpin: true
        };
    },
    methods: {
        validateEmail(email) {
            const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))*$/;
            return re.test(email);
        },
        subscribeNewsletter() {
            if (this.email == "") {
                this.$toasted.error("Please enter your email");
                this.spin = false;
                this.notSpin = true;
                return false;
            }
            if (!this.validateEmail(this.email)) {
                this.$toasted.error("Please enter a valid email");
                this.spin = false;
                this.notSpin = true;
                return false;
            }

            var request = {};
            request.email = this.email;
            this.$store
            .dispatch("SubscribeNewsletter", request)
            .then(() => {
            this.$toasted.success("Subscribed to newsletter successfully");
            this.spin = false;
            this.notSpin = true;
            })
            .catch(error => {
            this.handleAxiosErrors(error);
            this.spin = false;
            this.notSpin = true;
            });
        },
    }
}
</script>