<template>
    <section class="burger faq dark-template-bg" id="faq-section" >
        <div class="container ">
        <!-- container-start -->
        <h2 class="text-center mb5">FAQ</h2>
        <div class="row align-item-center">
            <!-- row start -->
            <div class="faq-box">
                <div class="faq-icon"><img class="img-responsive" src="images/faq-icon.svg" alt="questions"></div>
                <div class="col-sm-12">
                    <!-- column start -->    
                    <div class=" panel-group" id="accordion">
                    <div class="panel">
                        <span>01.</span>
                        <div class="panel-heading ">
                            <div class="" data-toggle="collapse" data-parent="#accordion" data-target="#collapse1">
                                What factors influence future demand for APX
                            </div>
                        </div>
                        <div id="collapse1" class="panel-collapse collapse in">
                            <div class="panel-body">The product readiness and usefullness is key to demand for the token. However, other factors such as the rewarding models like staking and token burn will play a huge role too.
                            </div>
                        </div>
                    </div>
                    <div class="panel">
                        <span>02.</span>
                        <div class="panel-heading ">
                            <div  class=" collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapse2">
                                How can I buy APX Token?
                            </div>
                        </div>
                        <div id="collapse2" class="panel-collapse collapse">
                            <div class="panel-body">Although APX is already tradeable on Pancakeswap, but the best way to own valuable APX tokens is to buy in volume and at a discount from the private sale by joining the waitlist. Funds realized from private sale will be used to make trading APX more fun.
                            </div>
                        </div>
                    </div>
                    <div class="panel">
                        <span>03.</span>
                        <div class="panel-heading ">
                            <div class="margin-0 collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapse3">
                                Can I provide liquidity for APX in Dex?
                            </div>
                        </div>
                        <div id="collapse3" class="panel-collapse collapse">
                            <div class="panel-body">Of course, everyone who is able to possess some APX tokens can provide liquidity for the APX tokens to ensure trading is not disrupted and to make profits from the liquidity, but ensure you are conversant with the risks involved with providing liquidity.
                            </div>
                        </div>
                    </div>

                    <div class="panel">
                        <span>04.</span>
                        <div class="panel-heading ">
                            <div class="margin-0 collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapse4">
                                What are the benefits to stakers?
                            </div>
                        </div>
                        <div id="collapse4" class="panel-collapse collapse">
                            <div class="panel-body">40% of all transaction fees collected will be used to buy BNB Smart Chain and sent into the staking contract every 10 minutes, the stakers can chose to withdraw the given profit share from the staking pool or totally withdraw his stake at any time.
                            </div>
                        </div>
                    </div>

                    <div class="panel">
                        <span>05.</span>
                        <div class="panel-heading ">
                            <div class="margin-0 collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapse5">
                                How are tokens burnt?
                            </div>
                        </div>
                        <div id="collapse5" class="panel-collapse collapse">
                            <div class="panel-body">10% of all transaction fees collected will be used to buy APX tokens and the bought tokens will be sent into the 0x address to burn the given tokens, so the more transactions on the ApeiroX platform, the less number of APX.
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <!-- column end -->
            </div>
        </div>
        <!-- row-end -->
        </div>
        <!-- conatainer-end -->
    </section>
</template>

<script>
export default {
    name: "Faq"
}
</script>