<template>
    <section class="burger roadmap dark-template-light-bg" id="roadmap">
        <div class="container">
        <!-- container-start -->
        <div class="row">
            <!-- row start -->
            <h2 class="text-center mb5">Roadmap</h2>
            <div class="col-sm-12">
                <!-- column start -->
                <div class="roadmap-list">
                    <div class="road-item">
                    <div class="road-content">
                        <h3>Q2, 2021</h3>
                        <p>Project conceptualization, Company registration, Deployment of smart contract, Whitepaper development, Airdrops and bounties (phase 1), Launching on exchanges, Marketing campaigns</p>
                        <span class="road-year">1</span>
                    </div>
                    </div>
                    <div class="road-item big">
                    <div class="road-content">
                        <h3>Q3, 2021 </h3>
                        <p>Product development, Launch of product (Beta phase), Onboarding phase (aggregators, verifiers and dispatchers), Market expansion across Africa</p>
                        <span class="road-year">2</span>
                    </div>
                    </div>
                    <div class="road-item">
                    <div class="road-content">
                        <h3>Q4 2021</h3>
                        <p>Strategic partnerships, Bounty campaigns (phase 2), Product updates, Market expansion across Africa</p>
                        <span class="road-year">3</span>
                    </div>
                    </div>
                    <div class="road-item big">
                    <div class="road-content">
                        <h3>Q1, 2022</h3>
                        <p>Scaling across other countries around the world (Europe, Asia, America), Financial licensings, Market expansion across Africa</p>
                        <span class="road-year">4</span>
                    </div>
                    </div>
                    <div class="road-item ">
                    <div class="road-content">
                        <h3>Q2, 2022</h3>
                        <p>Market expansion across Africa, Product upgrade, Strategic partnerships</p>
                        <span class="road-year">5</span>
                    </div>
                    </div>
                    <div class="road-item big">
                    <div class="road-content">
                        <h3>Q3, 2022</h3>
                        <p>More widespread marketing, increase userbase, product update, strategic partnerships</p>
                        <span class="road-year">6</span>
                    </div>
                    </div>
                </div>
            </div>
            <!-- column end -->
        </div>
        <!-- row end -->
        </div>
        <!-- container end -->
    </section>
</template>

<script>
export default {
    name: "RoadMap"
}
</script>