<template>
    <section class="burger dark-template-bg" id="service" >
        <div class="container">
        <!-- container-start -->
        <div class="row align-item-center">
            <!-- row start -->
            <div class="col-sm-12 col-lg-4">
                <!-- column start -->
                <h2>You can do lots of things with ApeiroX</h2>
                <p class="big-pera">ApeiroX is a hybrid of both e-commerce and decentralized finance. There are so much utility in the ecosystem such as</p>
            </div>
            <!-- column end -->
            <div class="col-lg-8 col-sm-12">
                <!-- column start -->
                <div class="services-list">
                    <div class="service-box">
                    <img src="images/bitcoin-shopping.svg" class="img-responsive" alt="cryptency">
                    <span>Buy Goods</span>
                    </div>
                    <div class="service-box">
                    <img src="images/digital-cash.svg" class="img-responsive" alt="cryptency">
                    <span>Save Fiat</span>
                    </div>
                    <div class="service-box">
                    <img src="images/fast-transaction.svg" class="img-responsive" alt="cryptency">
                    <span>Swap Cryptos</span>
                    </div>
                    <div class="service-box">
                    <img src="images/payment-integration.svg" class="img-responsive" alt="cryptency">
                    <span>Fiat P2P</span>
                    </div>
                    <div class="service-box">
                    <img src="images/payment-integration.svg" class="img-responsive" alt="cryptency">
                    <span>Crypto P2P</span>
                    </div>
                    <div class="service-box">
                    <img src="images/investment.svg" class="img-responsive" alt="cryptency">
                    <span>Get a Job</span>
                    </div>
                </div>
            </div>
            <!-- column end -->
        </div>
        <!-- row end -->
        </div>
        <!-- container end -->
    </section>
</template>

<script>
export default {
    name: "Service"
}
</script>