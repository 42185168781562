<template>
    <div class="burger about-special  ">
        <div class="container">
            <!-- container-start -->
            <div class="row align-item-center">
                <!-- row start -->
                <div class="col-sm-12 col-lg-7">
                    <!-- column start -->
                    <div class="dark-template-bg">
                    <div class="benifit-contant">
                        <h2 class="mt0">What's special  about ApeiroX</h2>
                        <p class="big-pera" >ApeiroX is not only unique for combining the features of e-commerce together with currency exchanges, but more unique in its ability to create good rewards for every participating member.</p>
                        <!-- <a href="/waitlist_form" class="redirect-btn mt2 btn-white">JOIN WAITLIST</a> -->
                    </div>
                    </div>
                </div>
                <!-- column end -->
                <div class="col-sm-12 col-lg-5 ">
                    <!-- column start -->
                    <div class="benifit-box">
                    <div class="row">
                        <!-- row start -->
                        <div class="media-item col-sm-6">
                            <!-- column start -->
                            <div class="media mb3">
                                <!-- <img src="images/accepted-worldwide.svg" alt="secure" class="pull-left" width="50"> -->
                                <div class="media-body">
                                <h3>Deflationary Model</h3>
                                </div>
                            </div>
                        </div>
                        <!-- column end -->
                        <div class="col-sm-6 media-item">
                            <!-- column start -->
                            <div class="media mb3">
                                <!-- <img src="images/decentralised-currency.svg" alt="secure" class="pull-left" width="50"> -->
                                <div class="media-body">
                                <h3>High Staking Rewards</h3>
                                </div>
                            </div>
                        </div>
                        <!-- column end -->
                    </div>
                    <!-- row end -->
                    <div class="row">
                        <!-- row start -->
                        <div class="col-sm-6 media-item">
                            <!-- column start -->
                            <div class="media ">
                                <!-- <img src="images/safe-and-secure.svg" alt="secure" class="pull-left" width="50"> -->
                                <div class="media-body">
                                <h3>Incentivized Usage</h3>
                                </div>
                            </div>
                        </div>
                        <!-- column end -->
                        <div class="col-sm-6 media-item">
                            <!-- column start -->
                            <div class="media ">
                                <!-- <img src="images/low-risk.svg" alt="secure" class="pull-left" width="50"> -->
                                <div class="media-body">
                                <h3>Low Gas Fees</h3>
                                </div>
                            </div>
                        </div>
                        <!-- column end -->
                    </div>
                    <!-- row end -->
                    </div>
                </div>
                <!-- column end -->
            </div>
            <!-- row end -->
        </div>
        <!-- container start -->
    </div>
</template>

<script>
export default {
    name: "AboutSpecial"
}
</script>