<template>
    <div class="site-loader">
        <div class="loader-dots">
        <div class="circle circle-1"></div>
        <div class="circle circle-2"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SiteLoader"
}
</script>