<template>
    <section class="" id="hero">
        <div class="banner burger  align-item-center parallax">
            <div class="container">
                <!-- container-start -->
                <div class="row align-item-center mt3">
                    <!-- row start -->
                    <div class="col-sm-12 col-lg-12 ">
                        <!-- column start -->
                        <div class="banner-text">
                        <h1 class="wow fadeIn" data-wow-delay="1s" data-wow-duration="2s"><strong class="template-color">ApeiroX</strong> the trust-less commerce and payment platform.</h1>
                        <p class="big-pera">Our suite of products include e-Commerce, DEX swaps, P2P and e-Wallets.</p>
                        <!-- <div class="mt3 banner-btn-group">
                            <a href="/buy_apx" class="redirect-btn btn-alpha ">PROCEED WITH PURCHASE </a>    
                            <a href="#buy-token" class="redirect-btn btn-alpha ">SALE INSTRUCTION  </a>    
                        </div> -->
                        </div>
                    </div>
                    <!-- column end -->
                    <!-- <div class="col-sm-6 col-lg-5 col-lg-offset-1">
                        <div class="timer-box text-center">
                        <h2 class="mt0 ">PRIVATE SALE ENDS IN </h2>
                        <div class="timer">
                            <div class="days"></div>
                            <div class="hours"></div>
                            <div class="minutes"></div>
                            <div class="seconds"></div>
                        </div>
                        <div class="timer-text"> <span><em class="template-color">1 APX</em> = 0.00000004 BNB Smart Chain</span>
                        <br /><span>Over 45k Community Members</span>
                        </div>
                        <a href="/waitlist_form" class="btn-alpha mt3 redirect-btn">Join Waitlist</a>
                        </div>
                    </div> -->
                    <!-- column end -->
                </div>
                <!-- row end -->
            </div>
        <!-- container end -->
        </div>
        <!--================= banner-end ==================-->
    </section>
</template>

<script>
export default {
    name: "Hero"
}
</script>