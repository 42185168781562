<template>
    <nav class="navbar" id="myNavbar">
        <!-- Main header start -->
        <div class="container">
        <!-- container-start -->
            <div class="navbar-header ">
                <button data-target="#navbarCollapse" data-toggle="collapse" class="navbar-toggle collapsed" type="button">
                    <!-- mobile toggle (hamburgur) -->
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar top"></span>
                    <span class="icon-bar mid"></span>
                    <span class="icon-bar btm"></span>
                </button>
                <a href="/#hero" class="site-logo navbar-brand"><img src="images/logo2.png" alt="" class="img-responsive"></a><!-- main logo -->
            </div>
            <div id="navbarCollapse" class="collapse navbar-collapse ">
                <ul class="nav navbar-nav navbar-right " id="nav_bar">
                    <!-- <li><a href="/buy_apx">Proceed with Purchase</a></li> -->
                    <li><a href="/#about">About ApeiroX</a></li>
                    <li><a href="/#service">Services</a></li>
                    <li><a href="/#roadmap">Roadmap</a></li>
                    <li><a href="/#faq-section">Faq</a></li>
                    <li><a href="https://apeirox.com" target="_blank">Main Site</a></li>
                    <li ><a href="https://apeirox.com/whitepaper-v1.1.pdf" target="_blank" class="menu-btn">White Paper</a></li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "NavBar"
}
</script>