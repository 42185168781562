import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Toasted from "vue-toasted";

import axios from "axios";
import handleAxiosErrors from "./mixins/handle-axios-errors";

axios.defaults.withCredentials = false;
axios.defaults.baseURL = "https://api.myjobdesk.com/api/";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

axios.interceptors.response.use(undefined, function(error) {
  if (error) {
    return Promise.reject(error);
  }
});

Vue.mixin(handleAxiosErrors);

Vue.use(Toasted, {
  duration: 5000,
  position: "bottom-center"
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
