<template>
    <section class=" burger dark-template-light-bg" id="contact">
        <div class="container">
        <!-- container-start -->
        <div class="row align-item-center">
            <!-- row start -->
            <div class="col-sm-7 col-sm-offset-1  ">
                <!-- column start -->
                <div class="contact-form">
                    <h2 class="text-center mb5">Send a message </h2>
                    <div id="form-messages" class="notification contact"></div>
                    <div class="row">
                        <!-- row-start -->
                        <div class="col-sm-6">
                            <!-- column start -->
                            <div class="form-group">
                                <input  id="first_name" class="form-control" v-model="first_name" name="first_name" required="" type="text" >  
                                <label for="first_name">First Name :</label>
                            </div>
                        </div>
                        <!-- column end -->
                        <div class="col-sm-6">
                            <!-- column start -->
                            <div class="form-group">
                                <input id="last_name" class="form-control" v-model="last_name" name="last_name" required="" type="text" placeholder=""> 
                                <label for="last_name">Last Name :</label>
                            </div>
                        </div>
                        <!-- column end -->
                    </div>
                    <!-- row end -->
                    <div class="form-group">
                        <input id="email" class="form-control" name="email" required="" type="text" placeholder="" v-model="email">
                        <label for="email">Email :</label>
                    </div>
                    <div class="form-group">
                        <textarea id="message" class="form-control" name="message" rows="5" required="" placeholder="" v-model="message"></textarea>
                        <label for="message">Message :</label>
                    </div>
                    <button type="button" @click.prevent="sendContactMessage" class="btn-white"> <i v-if="spin" class="fa fa-spinner fa-spin"></i><span v-if="notSpin">SUBMIT</span></button>
                </div>
            </div>
            <!-- column end -->
            <div class="col-sm-4 col-sm-pull-1">
                <!-- column start -->
                <div class="contact-info">
                    <h3 class="text-left mb3 mt0">Contact ApeiroX </h3>
                    <div class="contact-item">
                    <img src="images/mail.svg" alt="dasasdfsdf" class="pull-left" width="55">
                    <span>hello@apeirox.com </span>
                    </div>
                </div>
            </div>
            <!-- column end -->
        </div>
        <!-- row-end -->
        </div>
        <!-- conatainer-end -->
    </section>
</template>

<script>
export default {
    name: "Contact",
    data: function() {
        return {
            email: "",
            message: "",
            first_name: "",
            last_name: "",
            spin: false,
            notSpin: true
        };
    },
    methods: {
        validateEmail(email) {
            const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))*$/;
            return re.test(email);
        },
        sendContactMessage() {
            if (this.email == "") {
                this.$toasted.error("Please enter your email");
                this.spin = false;
                this.notSpin = true;
                return false;
            }
            if (!this.validateEmail(this.email)) {
                this.$toasted.error("Please enter a valid email");
                this.spin = false;
                this.notSpin = true;
                return false;
            }
            if (this.message == "") {
                this.$toasted.error("Please enter a message");
                this.spin = false;
                this.notSpin = true;
                return false;
            }
            if (this.first_name == "") {
                this.$toasted.error("Please fill your first name");
                this.spin = false;
                this.notSpin = true;
                return false;
            }
            if (this.last_name == "") {
                this.$toasted.error("Please fill your last name");
                return false;
            }

            var request = {};
            request.email = this.email;
            request.message = this.message;
            request.first_name = this.first_name;
            request.last_name = this.last_name;
            this.$store
                .dispatch("SendContactMessage", request)
                .then(() => {
                this.$toasted.success("Message sent successfully");
                this.spin = false;
                this.notSpin = true;
                })
                .catch(error => {
                this.handleAxiosErrors(error);
                this.spin = false;
                this.notSpin = true;
                });
            },
    }
}
</script>